import { SVGProps } from 'react';

export function TransactionsSymbol(props: Readonly<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      width="40"
      height="30"
      viewBox="0 0 40 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.1216 9.12153C10.567 9.41105 1.25 15.7014 1.25 15.7014L5.61901 29.1506C5.61901 29.1506 15.5414 24.5447 29.6486 23.0445L25.1216 9.09521V9.12153Z"
        fill="#FBFCFD"
      />
      <path
        d="M28.9118 20.7286L29.6487 23.071C15.5679 24.5449 5.64549 29.1507 5.64549 29.1507L2.14502 18.3335"
        stroke="#010205"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.905 12.27L25.2141 19.7986C24.709 20.2316 24.4925 20.8329 24.5406 21.4823C17.4209 22.4925 11.8166 24.3206 9.05048 25.3067C9.00237 25.1624 8.93021 24.9941 8.834 24.8497C8.35294 24.08 7.39082 23.8155 6.57302 24.1281L4.76904 18.3314C5.05768 18.2833 5.34631 18.1871 5.63495 18.0187C6.52491 17.4414 6.76544 16.2628 6.18817 15.3728C6.18817 15.3728 6.16412 15.3247 6.14006 15.2766C7.89593 14.4348 15.2562 11.4282 20.9808 11.1636C21.2935 11.8852 21.9669 12.4624 22.9291 12.246L22.905 12.27Z"
        fill="#AEDFEF"
      />
      <path
        d="M25.1902 19.7984C24.6851 20.2314 24.4686 20.8327 24.5167 21.4821C17.397 22.4923 11.7927 24.3204 9.02655 25.3065C8.97845 25.1622 8.90629 24.9939 8.81008 24.8495C8.32902 24.0798 7.3669 23.8153 6.54909 24.128L4.74512 18.3312C5.03375 18.2831 5.32239 18.1869 5.61103 18.0185C6.50099 17.4412 6.74152 16.2626 6.16424 15.3727C6.16424 15.3727 6.14019 15.3246 6.11614 15.2764C6.74152 14.9638 8.06443 14.4105 9.79625 13.7852"
        stroke="#010205"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25113 11.9639C4.17149 13.7536 1.27637 15.7012 1.27637 15.7012"
        stroke="#010205"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.697 3.95023C36.697 3.95023 32.1701 4.97668 25.1955 2.6869C17.4049 0.133926 11.0356 2.6869 11.0356 2.6869L13.0096 16.4782C13.0096 16.4782 18.7735 14.0832 26.3798 16.8467C35.0915 20.005 38.7236 18.11 38.7236 18.11L36.697 3.92391V3.95023Z"
        fill="#FBFCFD"
      />
      <path
        d="M11.0356 2.71342L13.0359 16.5048C13.0359 16.5048 18.7998 14.1097 26.4061 16.8732C35.1178 20.0315 38.7499 18.1366 38.7499 18.1366L36.7233 3.95043C36.7233 3.95043 32.1964 4.97689 25.2218 2.6871C20.3527 1.08162 16.0363 1.50273 13.5097 2.02912"
        stroke="#010205"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9688 6.41681L14.6045 10.7402L14.7063 11.4523C15.6981 11.2743 16.69 11.9101 16.9952 12.9274C17.046 13.0545 17.0714 13.2071 17.0714 13.3597C19.0043 13.1308 22.1578 13.0545 25.6674 14.3007C29.584 15.674 32.2035 16.1318 33.8311 16.2844C33.6531 15.2671 34.238 14.2753 35.2044 13.9955C35.3824 13.9447 35.5859 13.9192 35.7639 13.9192L35.0264 8.01902C34.0854 8.12075 33.1699 7.51038 32.8901 6.51854C32.8392 6.34051 32.8138 6.16249 32.8138 5.95903C31.1099 5.88274 28.465 5.57755 25.3368 4.63657C21.1406 3.36498 17.6309 3.56843 15.5964 3.89905C15.8507 4.96719 15.2658 6.03533 14.2485 6.31508C14.1468 6.31508 14.0705 6.36594 13.9688 6.36594V6.41681Z"
        fill="#AEDFEF"
      />
      <path
        d="M35.7639 13.9447C35.5858 13.9447 35.4078 13.9447 35.2044 14.021C34.238 14.3007 33.653 15.2926 33.831 16.2844C32.2034 16.1572 29.5839 15.674 25.6674 14.3007C22.1578 13.08 19.0042 13.1308 17.0714 13.3597C17.0714 13.2326 17.046 13.08 16.9951 12.9274C16.6899 11.8847 15.6981 11.2743 14.7062 11.4523L14.6045 10.7402"
        stroke="#010205"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6045 10.7655L13.9688 6.44212C14.0705 6.44212 14.1468 6.44212 14.2485 6.39125C15.2658 6.1115 15.8507 5.04336 15.5964 3.97522C17.631 3.6446 21.1151 3.44115 25.3368 4.71274C28.4395 5.65373 31.1099 5.95891 32.8138 6.03521C32.8138 6.21323 32.8393 6.39125 32.8901 6.59471C33.1699 7.58655 34.0854 8.19692 35.0264 8.09519L35.3062 10.2823"
        stroke="#010205"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
