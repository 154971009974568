import { SVGProps } from 'react';

export const InviteBannerIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 181"
      fill="none"
      {...props}
    >
      <mask
        id="mask0_4182_2869"
        maskUnits="userSpaceOnUse"
        x="0"
        y="-90"
        width="640"
        height="360"
      >
        <path d="M640 -89.0896H0V269.949H640V-89.0896Z" fill="white" />
      </mask>
      <g mask="url(#mask0_4182_2869)">
        <g opacity="0.25">
          <g opacity="0.5">
            <path
              d="M531.332 92.994C531.332 92.994 547.706 74.8535 558.321 97.125C568.757 119.396 551.484 120.474 551.484 120.474C551.484 120.474 559.041 120.833 561.2 130.532C563.359 140.411 543.927 155.139 527.374 140.59C527.374 140.59 518.018 156.037 506.862 133.765C502.883 122.989 511.54 122.989 511.54 122.989C511.54 122.989 497.686 77.7272 531.332 92.8144V92.994Z"
              fill="white"
            />
            <path
              d="M535.287 212.694L534.938 145.213L531.278 143.679L530.93 213.205L535.287 212.694Z"
              fill="white"
            />
            <path
              d="M535.471 148.673L536.015 212.481"
              stroke="#010205"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M530.93 212.48L531.692 147.236"
              stroke="#010205"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M532.052 92.994C532.052 92.994 548.425 74.8535 559.041 97.125C569.477 119.396 552.204 120.474 552.204 120.474C552.204 120.474 559.761 120.833 561.92 130.532C564.079 140.411 544.647 155.139 528.094 140.59C528.094 140.59 518.737 156.037 507.582 133.765C502.184 122.989 512.26 122.989 512.26 122.989C512.26 122.989 498.406 77.7272 532.052 92.8144V92.994Z"
              stroke="#010205"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <g opacity="0.3">
          <g opacity="0.7">
            <path
              d="M568.525 193.818C533.775 220.362 580.203 289.179 580.203 289.179L601.306 289.6C601.306 289.6 611.717 173.314 568.385 193.818H568.525Z"
              fill="#FBFCFD"
            />
          </g>
          <g opacity="0.7">
            <path
              d="M34.0061 -5.7509L68.3722 -20.6584V-14.7313L42.1029 -2.15872C35.8054 0.894629 31.6671 7.36054 31.6671 14.3653L32.2069 56.9326L32.5667 78.4857V89.4418L34.5459 220.018H19.2521L22.8507 10.5935C22.8507 3.40915 27.1689 -3.05677 33.8262 -5.93051H34.0061V-5.7509Z"
              fill="#FBFCFD"
            />
            <path
              d="M19.432 217.323L21.7711 89.9805"
              stroke="#010205"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32.9266 89.9805L34.7258 217.144"
              stroke="#010205"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.1309 57.651L23.0306 10.7731C23.0306 3.58876 27.3488 -2.87716 34.0061 -5.7509L68.3721 -20.6584V-14.7313L42.1028 -2.15872C35.8054 0.894628 31.667 7.36054 31.667 14.3653L32.2068 57.4714"
              stroke="#010205"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M94.1017 12.2099C104.717 12.2099 113.354 3.40909 113.354 -7.36745C113.354 -18.144 104.717 -26.9448 94.1017 -26.9448C83.486 -26.9448 74.8495 -18.144 74.8495 -7.36745C74.8495 3.40909 83.486 12.2099 94.1017 12.2099Z"
              fill="#FBFCFD"
            />
            <path
              d="M94.1017 12.2099C104.717 12.2099 113.354 3.40909 113.354 -7.36745C113.354 -18.144 104.717 -26.9448 94.1017 -26.9448C83.486 -26.9448 74.8495 -18.144 74.8495 -7.36745C74.8495 3.40909 83.486 12.2099 94.1017 12.2099Z"
              stroke="#010205"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M35.6255 67.5295H38.1445C39.224 67.5295 40.1237 68.4276 40.1237 69.5052V81.0002C40.1237 82.0779 39.224 82.9759 38.1445 82.9759H35.6255V67.7092V67.5295Z"
              fill="#FBFCFD"
            />
            <path
              d="M22.4908 60.7043H32.2069C33.1065 60.7043 33.6463 61.4228 33.6463 62.1412V64.4761H20.8715V62.1412C20.8715 61.2432 21.5912 60.7043 22.3109 60.7043H22.4908Z"
              fill="#FBFCFD"
            />
            <path
              d="M35.6254 86.7476H19.6119L20.1517 64.4761H35.0857L35.6254 86.7476Z"
              fill="#FBFCFD"
            />
            <path
              d="M35.6254 86.7476H19.6119L20.1517 64.4761H35.0857L35.6254 86.7476Z"
              stroke="#010205"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M35.6255 67.5295H38.1445C39.224 67.5295 40.1237 68.4276 40.1237 69.5052V81.0002C40.1237 82.0779 39.224 82.9759 38.1445 82.9759H35.6255V67.7092V67.5295Z"
              stroke="#010205"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.4908 60.7043H32.2069C33.1065 60.7043 33.6463 61.4228 33.6463 62.1412V64.4761H20.8715V62.1412C20.8715 61.2432 21.5912 60.7043 22.3109 60.7043H22.4908Z"
              stroke="#010205"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <path
          opacity="0.7"
          d="M473.7 106.984H149.793C147.065 106.984 144.882 109.135 144.882 111.824V132.079C144.882 134.768 147.065 136.919 149.793 136.919H473.7C476.428 136.919 478.611 134.768 478.611 132.079V111.824C478.611 109.135 476.428 106.984 473.7 106.984Z"
          fill="#FBFCFD"
        />
        <g opacity="0.7">
          <path
            d="M181.292 138.416H166.122V214.749H181.292V138.416Z"
            fill="#FBFCFD"
          />
          <path
            d="M165.666 150.37V142.363"
            stroke="#010205"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M181.706 156.921V214.243"
            stroke="#010205"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M181.706 142.18V149.459"
            stroke="#010205"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M455.86 138.416H439.173V214.749H455.86V138.416Z"
            fill="#FBFCFD"
          />
          <path
            d="M455.335 141.453V151.462"
            stroke="#010205"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M184.803 137.268H149.255C146.703 137.268 144.333 135.084 144.333 132.355V111.973C144.333 109.426 146.521 107.06 149.255 107.06H186.262"
            stroke="#010205"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M336.477 137.268H316.3"
            stroke="#010205"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M442.206 107.241L474.111 107.241C476.663 107.241 479.033 109.425 479.033 112.155V132.536C479.033 135.084 476.846 137.449 474.111 137.449H448.772"
            stroke="#010205"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M302.647 107.241H342.846"
            stroke="#010205"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M474.291 155.466H149.801C147.067 155.466 144.879 157.65 144.879 160.38V180.943C144.879 183.672 147.067 185.856 149.801 185.856H474.473C477.207 185.856 479.395 183.672 479.395 180.943V160.38C479.395 157.65 477.207 155.466 474.473 155.466H474.291Z"
            fill="#FBFCFD"
          />
          <path
            d="M318.575 155.466H332.986"
            stroke="#010205"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M174.047 185.856H149.619C147.067 185.856 144.697 183.672 144.697 180.942V160.561C144.697 158.013 146.885 155.648 149.619 155.648H183.162"
            stroke="#010205"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M452.966 155.466H474.112C476.664 155.466 479.034 157.65 479.034 160.38V180.761C479.034 183.309 476.847 185.674 474.112 185.674H448.955"
            stroke="#010205"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M370.568 99.6667C370.568 99.6667 370.568 88.2023 367.833 73.0984"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M324.081 283.099C311.503 265.083 305.305 247.068 302.206 237.423C298.924 227.778 294.185 213.038 329.55 189.927C382.599 155.17 385.151 212.128 385.151 212.128C385.151 212.128 363.275 226.686 337.936 236.331C331.92 238.333 352.338 260.352 355.619 283.099H324.081Z"
          fill="#9D5B3A"
        />
        <path
          d="M316.241 199.753C316.241 199.753 348.325 179.19 353.247 230.507L376.946 222.5C376.946 222.5 388.066 185.377 366.008 181.01C344.132 176.461 316.241 199.935 316.241 199.935V199.753Z"
          fill="#2A2F3E"
        />
        <path
          d="M349.418 284.007C340.85 271.815 328.454 248.886 328.454 228.869C328.454 228.869 331.371 215.038 370.018 197.387C425.983 171.91 423.978 216.13 423.978 216.13C423.978 216.13 412.128 231.962 377.674 239.969C363.82 243.063 372.205 244.336 390.253 283.461L349.783 284.189H349.418V284.007Z"
          fill="#9D5B3A"
        />
        <path
          d="M350.879 206.668C350.879 206.668 383.51 189.38 384.786 238.332C384.786 238.332 420.152 226.685 425.621 218.86C431.09 211.035 429.631 182.647 407.209 180.646C384.786 178.644 350.697 206.668 350.697 206.668H350.879Z"
          fill="#2A2F3E"
        />
        <path
          d="M352.7 106.946C347.961 112.951 343.585 125.507 349.784 135.152C355.982 144.796 351.242 150.802 351.242 167.725C351.242 184.649 352.518 176.642 350.33 176.642C344.497 176.642 341.398 175.914 336.84 179.372C332.283 183.011 334.106 186.105 334.106 186.105C334.106 186.105 322.439 181.373 324.08 193.93C324.08 193.93 343.95 178.28 354.159 195.022C364.367 211.945 372.753 188.471 389.707 199.207C406.66 209.944 397.545 234.328 400.644 233.418C434.005 222.5 432.729 213.583 432.911 191.2C433.093 168.817 432.911 142.977 432.911 134.242C433.093 111.859 431.088 86.2004 426.166 81.4691C418.874 74.372 401.191 72.0063 401.191 72.0063C395.358 72.5523 359.081 88.5661 357.987 89.112C357.987 89.112 351.971 98.0288 353.065 106.946H352.7Z"
          fill="#FBFCFD"
        />
        <path
          d="M405.907 33.7939C413.199 28.8805 419.579 29.2445 423.225 35.7956C423.225 35.7956 432.887 35.7956 434.163 45.2583C434.163 45.2583 445.465 43.0746 443.46 56.5408C443.46 56.5408 447.471 57.2686 447.106 54.357C447.106 54.357 448.747 63.6378 436.533 64.5477C436.533 64.5477 455.31 74.0104 440.908 80.0156C426.507 86.2027 343.014 96.5753 336.634 88.5684C330.254 80.7435 335.176 78.1958 335.176 78.1958C335.176 78.1958 329.889 76.376 329.707 71.6447C329.524 65.2756 331.347 60.9082 337.728 62.9099C337.728 62.9099 334.264 59.2704 339.186 55.9948C344.108 52.5373 343.744 53.4472 343.744 53.4472C343.744 53.4472 344.29 46.8961 351.035 45.6222C361.244 43.8025 392.782 36.8874 392.782 36.8874C392.782 36.8874 398.797 23.6032 400.62 30.7003L406.089 34.1578V33.7939H405.907Z"
          fill="#010205"
        />
        <path
          d="M398.46 73.644C392.626 74.19 356.349 90.2038 355.255 90.7497C354.162 91.2956 352.521 93.6613 347.052 102.76C343.771 108.401 340.854 131.148 339.395 147.526C337.937 163.722 337.39 161.902 333.197 171.729C329.004 181.373 329.916 188.652 329.916 188.652L437.289 153.167C437.289 153.167 432.631 90.0107 425.339 82.9136C418.047 75.8166 398.46 74.008 398.46 74.008V73.644Z"
          fill="#FBFCFD"
        />
        <path
          d="M429.451 132.058C427.628 116.045 431.092 87.2925 426.17 82.5611C418.878 75.4641 401.195 73.0984 401.195 73.0984C395.362 73.6443 359.084 89.6581 357.991 90.2041C357.991 90.2041 344.318 93.8436 340.672 140.065"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M351.974 168.816C351.974 174.458 351.427 178.097 349.24 177.551C347.052 177.005 341.765 176.641 336.843 179.371C331.921 182.101 331.921 185.558 331.921 185.558C331.921 185.558 322.806 183.92 324.083 193.929C324.083 193.929 345.047 180.463 354.162 195.021C363.094 209.579 372.938 188.652 389.71 199.206C406.481 209.761 397.548 234.327 400.647 233.418C429.633 224.137 433.096 214.856 432.732 194.657"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M353.25 107.855C348.51 113.861 344.135 124.597 350.333 134.242"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M410.31 79.8318C410.31 79.8318 400.284 97.3014 376.038 104.398"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M408.846 76.3728C408.846 76.3728 392.803 103.487 360.719 104.215C351.604 104.215 355.25 94.7523 363.454 88.7471C371.657 82.7419 370.563 83.1059 370.563 83.1059C370.563 82.378 370.381 80.5582 370.199 79.6483C369.834 73.8251 368.923 66.0002 368.193 64.7264C366.37 60.7229 377.126 55.8096 381.866 53.4439C383.506 52.716 389.887 39.9777 389.522 54.7177C389.158 69.4577 389.522 71.6414 408.481 76.0088H408.663V76.3728H408.846Z"
          fill="#9D5B3A"
        />
        <path
          d="M387.703 54.9005C387.886 63.4533 377.312 81.4689 368.744 84.5624C368.744 82.7427 368.744 80.9229 368.198 79.6491C367.833 73.8259 366.921 66.001 366.192 64.7271C364.369 60.7237 375.125 55.8103 379.865 53.4447C381.505 52.7168 384.604 48.5313 387.521 54.7185H387.703V54.9005Z"
          fill="#010205"
        />
        <path
          d="M393.9 66.1835C393.717 66.1835 393.17 65.8195 392.806 66.1835L393.17 67.0934C390.254 70.5509 380.227 80.7415 372.024 78.0119L373.118 80.3776C373.118 80.3776 375.488 82.3793 382.05 79.4677C391.165 75.1003 395.358 68.5492 395.358 68.5492L393.9 66.3655V66.1835Z"
          fill="#2A2F3E"
        />
        <path
          d="M375.306 83.834C376.764 83.834 377.858 82.0143 377.858 80.3765C377.858 78.7387 376.035 77.8289 374.394 77.8289C372.753 77.8289 371.842 79.6486 371.842 81.2864C371.842 82.9242 373.665 83.834 375.306 83.834Z"
          fill="#010205"
        />
        <path
          d="M375.304 82.1972C376.033 82.1972 376.58 81.4693 376.398 80.7414C376.216 80.0135 375.669 79.4676 374.94 79.6495C374.21 79.6495 373.663 80.3774 373.846 81.1053C374.028 81.8332 374.575 82.3792 375.304 82.1972Z"
          fill="#FBFCFD"
        />
        <path
          d="M383.878 22.5094C375.128 25.421 367.836 32.7 376.586 43.9825C384.972 55.4469 400.832 43.2546 397.55 32.5181C394.087 21.7815 387.342 21.2356 384.06 22.3274H383.878V22.5094Z"
          fill="#FBFCFD"
        />
        <path
          d="M383.878 22.5094C375.128 25.421 367.836 32.7 376.586 43.9825C384.972 55.4469 400.832 43.2546 397.55 32.5181C394.087 21.7815 387.342 21.2356 384.06 22.3274H383.878V22.5094Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M375.85 34.883C375.85 34.883 378.767 49.0771 390.252 44.3457C401.737 39.6144 397.544 32.5173 397.544 32.5173"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M384.787 22.8736C384.787 22.8736 380.23 13.9568 379.318 12.501C378.407 11.2272 373.667 11.9551 373.667 11.9551L380.23 25.4213L384.787 22.8736Z"
          fill="#FBFCFD"
        />
        <path
          d="M384.787 22.8736C384.787 22.8736 380.23 13.9568 379.318 12.501C378.407 11.2272 373.667 11.9551 373.667 11.9551L380.23 25.4213L384.787 22.8736Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M384.786 22.8746C379.864 24.5123 376.035 28.5158 380.957 35.4308C385.879 42.1639 394.63 35.4308 392.624 29.0617C390.619 22.8746 386.791 22.3286 384.786 22.8746Z"
          fill="#FBFCFD"
        />
        <path
          d="M384.786 22.8746C379.864 24.5123 376.035 28.5158 380.957 35.4308C385.879 42.1639 394.63 35.4308 392.624 29.0617C390.619 22.8746 386.791 22.3286 384.786 22.8746Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M382.599 31.6084C382.599 31.6084 384.422 37.7955 389.709 35.6118C395.178 33.4281 392.443 29.2427 392.443 29.2427"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M395.725 29.0609C395.725 29.0609 396.637 33.2464 396.272 34.1563C394.996 37.2498 363.458 29.9708 363.458 29.9708L345.958 34.1563C345.958 34.1563 344.135 27.6051 344.864 22.6918C345.593 17.7785 364.552 8.13379 364.552 8.13379L388.069 14.6849L395.543 29.0609H395.725Z"
          fill="#FBFCFD"
        />
        <path
          d="M348.329 41.07C348.329 41.07 345.048 39.9781 345.048 38.3404C345.048 36.7026 345.595 33.791 345.595 33.791C345.595 33.791 342.678 29.0596 343.589 27.2399C344.501 25.2381 350.152 14.8655 366.012 13.4097C384.424 11.59 396.091 30.6974 396.273 33.791C396.273 36.8846 393.539 39.7962 393.539 39.7962C393.539 39.7962 361.454 28.5137 348.329 40.888V41.07Z"
          fill="#FA7353"
        />
        <path
          d="M392.811 33.2462C392.811 33.2462 393.723 33.2462 392.811 31.6084C392.811 31.6084 392.993 31.0625 392.811 30.5166"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M386.79 27.0597C386.79 27.0597 388.066 26.1498 386.972 24.876C386.972 24.876 387.337 24.1481 386.972 23.4202"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M370.932 20.5068C370.932 20.5068 371.479 19.4149 370.932 18.1411C370.932 18.1411 371.115 17.4132 370.932 16.6853"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M361.092 21.781C361.092 21.781 361.092 19.9612 360.363 19.0514C360.363 19.0514 360.363 17.9595 360.18 17.4136"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M352.882 26.1486C352.882 26.1486 351.789 24.3288 351.971 23.055C351.971 23.055 351.971 21.9632 351.606 21.4172"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M379.495 22.3268C379.495 22.3268 380.042 21.053 379.495 20.1431V19.0513"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M347.23 31.2445C347.23 31.2445 346.318 30.6986 346.865 29.6067C346.865 29.6067 346.501 28.1509 346.318 27.605"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M344.682 22.8732C344.682 22.8732 354.526 10.1349 370.568 11.7727C388.433 13.4104 395.543 29.2423 395.543 29.2423C395.543 29.2423 396.636 24.6929 393.72 20.1435C390.803 15.4122 387.704 11.5907 382.235 7.9512C372.573 2.67391 361.635 6.49539 356.531 9.04304C349.421 12.8645 346.869 17.05 345.775 19.0517C344.682 21.0534 344.317 22.8732 344.317 22.8732H344.682Z"
          fill="#FA7353"
        />
        <path
          d="M347.962 38.7052C347.597 40.8889 350.332 49.6237 350.332 49.6237C350.332 60.1783 353.613 65.8195 362.363 70.0049C370.931 74.1904 375.489 70.3689 375.489 70.3689C375.489 70.3689 388.067 62.7259 389.343 51.8074C389.343 51.8074 390.073 44.3464 394.63 38.3413C393.719 37.0674 392.442 35.6116 391.166 33.9739C384.421 26.1489 374.213 18.8699 361.999 22.8734C347.597 27.7867 348.144 36.5215 347.78 38.5232L347.962 38.7052Z"
          fill="#9D5B3A"
        />
        <path
          d="M356.349 41.0706C354.709 38.523 349.787 40.1607 350.516 43.2543C351.245 41.0706 354.162 40.5247 356.167 41.2526H356.349V41.0706Z"
          fill="#010205"
        />
        <path
          d="M361.267 39.6143C363.272 35.4289 366.189 36.1568 370.017 35.9748C368.376 35.2469 366.189 34.701 364.366 35.2469C362.361 35.7928 361.085 37.6126 361.085 39.7963H361.267V39.6143Z"
          fill="#010205"
        />
        <path
          d="M358.352 49.8054C358.352 49.8054 357.441 51.4432 358.352 53.2629C359.264 54.9007 363.092 54.9007 363.092 54.9007"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M390.255 38.5234C390.255 38.5234 394.083 32.8822 396.453 37.4316C398.823 41.9809 397.547 49.2599 390.984 49.2599C384.421 49.2599 390.255 38.5234 390.255 38.5234Z"
          fill="#9D5B3A"
        />
        <path
          d="M394.993 40.8892C394.993 40.8892 392.623 39.6153 390.071 43.8008"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M347.967 39.0691C347.967 39.0691 349.79 31.6081 357.629 27.7866C365.285 23.7831 372.03 23.7831 379.14 27.7866C379.14 27.7866 378.593 29.0604 380.234 30.8802C381.874 32.6999 385.885 33.9738 385.338 35.6115C384.791 37.0673 383.151 38.7051 384.244 39.9789C385.885 41.7987 384.791 41.4347 384.609 43.4365C384.609 45.6202 387.89 46.894 383.515 46.53C383.88 46.53 387.708 47.4399 386.432 44.7103C385.885 43.4365 386.432 43.4365 387.161 42.1626C387.89 40.8888 386.067 39.251 387.343 38.3412C388.802 37.6133 392.083 38.3412 391.354 34.1557C384.609 26.3308 374.4 19.0518 362.186 23.0552C347.785 27.9686 347.42 36.7034 347.785 38.5231V38.8871H347.967V39.0691Z"
          fill="#010205"
        />
        <path
          d="M366.918 57.9931C366.918 59.8129 366.918 63.4524 369.47 62.9065C372.752 61.9966 373.116 55.0815 373.116 55.0815C372.023 55.9914 368.923 57.2652 366.918 58.1751V57.9931Z"
          fill="#FBFCFD"
        />
        <path
          d="M366.917 57.9946C365.641 58.5405 364.729 58.9045 364.729 58.9045"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M366.918 57.9931C366.918 59.8129 366.918 63.4524 369.47 62.9065C372.752 61.9966 373.116 55.0815 373.116 55.0815C372.023 55.9914 368.923 57.2652 366.918 58.1751V57.9931Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M367.65 44.3471C368.379 43.9832 368.743 43.0733 368.379 42.1634C368.014 41.2535 367.103 41.0716 366.191 41.4355C365.28 41.7995 365.097 42.7093 365.462 43.6192C365.827 44.5291 366.738 44.7111 367.65 44.3471Z"
          fill="#010205"
        />
        <path
          d="M355.624 48.35C356.353 47.9861 356.718 47.0762 356.353 46.1663C355.989 45.2565 355.077 45.0745 354.166 45.4384C353.254 45.8024 353.072 46.7123 353.436 47.6221C353.801 48.532 354.713 48.714 355.624 48.35Z"
          fill="#010205"
        />
        <path
          d="M346.688 38.8864C346.87 37.6126 349.058 27.24 362.183 22.8726C374.397 18.6872 384.606 25.9662 391.351 33.9731C392.445 35.2469 392.809 35.6109 392.809 35.6109"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M336.48 164.269C336.48 164.269 333.198 169.728 331.74 178.281"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M356.716 146.434C356.716 146.434 365.831 148.981 368.383 151.893C370.753 154.622 371.847 164.995 369.112 167.179C365.284 170.454 354.529 165.541 354.529 165.541C354.529 165.541 349.971 147.525 356.716 146.434Z"
          fill="#9D5B3A"
        />
        <path
          d="M340.667 143.887C340.667 143.887 351.969 144.797 356.709 146.253C356.709 146.253 357.985 150.984 355.798 150.802C353.61 150.62 348.323 148.983 347.047 148.983C345.771 148.983 337.932 147.345 340.849 143.887H340.667Z"
          fill="#9D5B3A"
        />
        <path
          d="M355.801 150.074C355.801 150.074 349.785 149.71 343.222 151.893C336.659 154.077 336.477 156.261 336.659 157.353C336.842 158.445 338.3 158.627 338.847 158.627C338.847 158.627 338.118 161.356 341.034 161.174C346.686 160.628 349.238 158.99 353.978 160.082C353.978 160.082 343.222 160.082 343.404 163.358C343.769 166.087 346.139 163.54 351.79 164.996C357.441 166.451 359.082 167.361 359.629 167.179C363.822 166.269 355.618 149.71 355.618 149.71V150.074H355.801Z"
          fill="#9D5B3A"
        />
        <path
          d="M368.742 172.82C360.357 167.907 368.195 167.543 366.008 156.261C363.82 144.978 357.804 143.704 364.549 141.157C376.946 136.243 396.634 142.977 396.634 142.977C396.634 142.977 396.816 140.247 404.108 139.337C401.373 116.954 408.483 85.6543 427.26 86.0183C438.015 95.663 454.422 169.363 447.13 175.732C447.13 175.732 421.426 202.3 368.742 172.82Z"
          fill="#FBFCFD"
        />
        <path
          d="M412.495 95.6629C415.047 92.2054 418.329 89.2938 422.339 87.1101"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M404.291 139.337C404.291 139.337 402.468 120.229 405.385 110.403"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M426.348 132.423C426.348 132.423 415.775 131.695 413.587 137.883"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M404.291 139.339C418.51 138.793 434.37 147.709 434.37 147.709"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M382.054 181.009C379.684 180.281 376.95 179.371 376.585 179.007C381.507 173.184 389.528 160.628 372.939 140.247C374.58 139.519 376.585 139.883 378.043 139.701C379.32 141.156 395.362 160.264 382.054 181.009Z"
          fill="#2A2F3E"
        />
        <path
          d="M374.76 177.551C373.484 176.459 372.755 176.095 370.932 174.64C379.317 163.357 370.932 145.888 365.281 141.156C365.645 140.792 368.015 139.518 369.838 139.882C372.755 144.068 385.516 161.537 374.76 177.915V177.551Z"
          fill="#2A2F3E"
        />
        <path
          d="M404.294 139.337C397.002 140.065 396.82 142.977 396.82 142.977C393.903 142.067 377.679 132.24 363.095 141.157C348.511 149.892 364.006 142.977 367.288 155.897C370.569 168.817 359.631 166.634 367.288 172.093C387.705 187.379 432.003 195.386 447.498 175.732C454.79 169.363 439.113 96.9371 427.263 83.4709"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M364.916 156.625C363.64 145.525 359.447 147.344 359.447 147.344L363.093 148.8C363.093 148.8 366.739 149.892 368.015 157.899C369.291 165.906 365.463 168.453 364.369 168.635C363.458 168.817 359.447 167.362 359.447 167.362C359.447 167.362 366.192 167.726 364.916 156.625Z"
          fill="#010205"
        />
        <path
          d="M360.54 147.89C352.519 145.16 343.222 143.523 340.852 144.068C338.482 144.614 337.935 146.98 348.508 149.346"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M353.067 150.074C353.067 150.074 344.134 148.8 337.936 155.533C337.936 155.533 335.931 158.263 339.03 159.173C339.03 159.173 338.483 161.538 341.217 161.72"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M349.241 154.805C350.335 154.805 351.428 154.623 353.069 154.623"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M341.582 157.535C341.582 157.535 342.676 156.08 346.504 155.352"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M353.797 160.447C353.797 160.447 343.953 159.901 343.953 163.54"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M354.892 118.593C354.892 118.593 368.929 123.324 387.706 113.498"
          stroke="#FBFCFD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M365.825 87.4741L367.83 85.4724C367.83 85.4724 355.981 92.2055 355.251 100.03C354.522 107.855 368.195 103.488 368.195 103.488C368.195 103.488 355.434 104.398 357.257 98.5746C359.08 92.7514 362.543 90.3857 365.825 87.4741Z"
          fill="#010205"
        />
        <path
          d="M299.472 208.852C296.556 192.11 257.544 165.36 253.533 165.542C222.361 167.362 224.73 215.039 224.73 215.039C240.043 226.686 255.903 242.153 255.903 242.153C255.903 242.153 229.106 249.433 230.746 284.918L251.893 285.282C251.893 285.282 301.842 222.136 299.29 208.67L299.472 208.852Z"
          fill="#2A2F3E"
        />
        <path
          d="M254.627 76.5554C254.627 76.5554 268.482 77.4653 278.873 101.85C289.264 126.235 303.483 130.238 308.041 139.883C312.598 149.346 289.811 178.462 257.909 133.15C236.945 103.124 254.992 76.5554 254.992 76.5554H254.627Z"
          fill="#FFDC69"
        />
        <path
          d="M298.381 114.77C298.381 114.77 306.949 131.33 306.767 137.153C306.22 153.349 283.798 149.164 285.074 137.335C286.168 124.597 288.902 115.68 288.902 115.68L298.746 114.77H298.381Z"
          fill="#7D4C34"
        />
        <path
          d="M293.087 108.765C293.087 108.765 300.744 110.221 303.114 113.496C305.301 116.59 306.577 138.245 291.082 128.6C291.082 128.6 287.436 130.238 283.972 127.327C280.326 124.415 280.691 101.304 293.27 108.947H293.087V108.765Z"
          fill="#7D4C34"
        />
        <path
          d="M282.7 115.681C285.434 112.588 290.174 105.309 290.174 105.309C290.174 105.309 290.356 104.945 290.538 104.763C292.179 101.123 287.986 98.2117 284.887 101.305C281.606 104.763 277.231 113.498 278.871 115.863C279.418 116.773 281.059 117.137 282.7 115.317V115.681Z"
          fill="#7D4C34"
        />
        <path
          d="M287.803 110.221C287.074 113.133 287.074 115.498 287.439 116.226C288.168 117.864 290.902 117.864 291.632 116.226C291.632 116.226 292.361 118.41 294.001 118.046C296.918 117.682 299.288 112.223 299.47 110.221C299.47 108.947 299.288 106.4 296.189 106.582C295.642 104.944 293.272 104.034 291.267 104.944C289.444 105.854 288.715 106.4 287.621 110.585V110.221H287.803Z"
          fill="#7D4C34"
        />
        <path
          d="M298.743 110.04C297.467 111.132 294.732 117.319 296.92 119.32C298.925 121.322 303.3 122.96 303.3 118.229C303.118 107.856 299.472 109.312 298.743 110.04Z"
          fill="#7D4C34"
        />
        <path
          d="M285.804 110.222C285.804 110.222 289.633 103.671 293.461 104.581"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M289.631 113.861C289.631 113.861 291.454 109.675 294.553 108.219"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M298.378 110.22C295.826 112.039 294.368 113.859 294.186 115.315C294.003 116.771 296.009 118.227 299.837 114.223L299.472 118.227"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M289.445 100.577C289.445 100.577 285.981 98.2108 282.882 103.488C276.319 114.771 279.236 116.044 280.694 116.044C282.153 116.044 284.705 112.405 284.705 112.405"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M290.723 129.692C290.723 129.692 297.832 136.062 302.572 128.601"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M280.335 117.864C279.606 117.864 277.783 117.864 277.965 120.229C278.148 122.595 279.424 126.416 286.898 124.961C291.638 124.051 291.455 119.865 288.356 119.865C285.257 119.865 282.341 118.409 280.335 118.046V117.864Z"
          fill="#7D4C34"
        />
        <path
          d="M281.421 118.409C281.421 118.409 291.082 120.593 291.082 122.777C291.082 124.961 285.431 126.598 281.421 124.961"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M281.97 23.6007C279.783 17.7775 271.032 5.03919 252.802 5.22117C219.807 5.58512 226.005 50.1691 215.432 68.5486C217.619 85.2903 269.574 82.5606 277.413 75.6456C276.866 66.5468 286.71 36.157 281.97 23.6007Z"
          fill="#010205"
        />
        <path
          d="M175.328 220.68C162.749 208.124 182.437 166.269 195.381 148.254C195.381 148.254 212.699 142.431 241.319 128.601C244.236 135.88 248.794 145.16 255.721 170.273C255.721 170.273 252.804 227.595 236.397 226.867C193.922 224.502 180.068 225.593 175.146 220.68H175.328Z"
          fill="#2A2F3E"
        />
        <path
          d="M254.99 76.7376C254.99 76.7376 265.563 98.9386 250.068 104.762C234.755 110.585 205.77 78.7394 220.171 70.3685C243.141 61.2697 236.942 44.1641 236.942 44.1641L263.558 51.2611L256.266 72.9161L254.99 76.7376Z"
          fill="#7D4C34"
        />
        <path
          d="M216.523 162.994C237.67 164.814 259.181 161.357 273.035 148.618C273.035 148.618 279.233 128.055 274.676 111.859C270.665 98.0292 263.556 80.3776 263.556 80.3776L254.988 76.1921C254.988 76.1921 267.019 96.2094 252.071 101.669C238.581 106.764 221.081 68.3672 221.081 68.3672C221.081 68.3672 200.481 79.2857 197.2 84.563C188.632 99.485 187.173 151.53 187.173 151.53C187.173 151.53 211.054 162.084 216.523 162.448V162.812V162.994Z"
          fill="#FFDC69"
        />
        <path
          d="M257.54 94.571C259.363 86.3821 254.988 76.5554 254.988 76.5554L256.993 77.6473C256.993 77.6473 266.837 97.3006 251.889 101.85C251.889 101.85 256.811 98.3924 257.54 94.389V94.571Z"
          fill="#010205"
        />
        <path
          d="M226.918 97.2998C213.792 70.7314 197.021 85.1075 197.021 85.1075C190.276 107.126 194.469 146.797 210.511 166.268C215.433 175.003 231.111 182.828 234.757 180.462C247.7 172.274 245.147 146.433 243.871 144.977C239.861 139.882 234.027 140.61 232.204 140.246L226.553 97.1178H226.918V97.2998Z"
          fill="#FFDC69"
        />
        <path
          d="M228.19 106.035C228.19 106.035 229.467 127.872 231.472 137.517"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M234.574 115.135C234.574 115.135 235.121 117.682 229.47 123.869"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M230.567 129.51C230.567 129.51 232.39 130.237 234.213 128.418"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M214.886 140.975C214.886 140.975 221.266 138.427 224.183 139.519"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M229.1 139.702C229.1 139.702 225.454 140.794 217.251 148.801"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M240.771 145.524C237.125 148.618 232.385 158.262 231.656 164.086C230.562 171.911 233.479 176.46 233.479 176.46"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.201 122.231C197.389 142.43 201.582 170.819 228.015 179.735"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M278.693 48.3496C278.693 60.5419 270.49 70.7325 259.187 70.3686C248.432 70.0046 241.505 57.0844 239.864 43.4362C239.682 41.0705 239.499 38.7049 239.499 36.3392C239.499 27.2404 242.234 18.6876 247.703 14.5022C261.74 4.12957 277.053 12.3185 281.975 23.237C282.339 23.7829 282.704 25.6026 282.704 25.6026C285.985 35.2473 279.787 44.1641 278.511 47.9856V48.3496H278.693Z"
          fill="#7D4C34"
        />
        <path
          d="M277.413 24.1463C265.928 21.5987 266.475 29.0597 260.642 33.0631C254.626 37.2486 253.167 32.3352 250.068 32.6992C247.152 33.0631 250.615 39.0683 246.605 40.5241C244.053 41.434 242.959 39.9782 242.594 37.9764V35.0648C242.412 35.0648 238.766 36.8846 238.766 36.8846C238.766 27.7858 239.495 18.1412 244.964 14.1377C259.912 1.39943 276.583 11.8255 281.869 22.7441C282.234 23.29 283.341 27.8807 283.341 27.8807C283.341 27.8807 279.054 24.8742 277.231 24.5103H277.048L277.413 24.1463Z"
          fill="#010205"
        />
        <path
          d="M272.126 50.7151C272.126 50.7151 275.59 52.3529 275.408 54.9005C275.225 57.2662 271.215 58.54 271.215 58.54"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M242.777 35.6117C242.777 35.6117 240.225 27.7868 235.303 31.0623C230.381 34.3379 235.85 47.2581 240.589 47.804C245.147 48.168 244.053 39.4332 242.959 35.6117H242.777Z"
          fill="#7D4C34"
        />
        <path
          d="M236.031 37.0659C236.031 37.0659 237.49 32.6985 241.135 42.7071"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M256.818 43.4356C256.818 43.4356 263.928 39.7961 268.121 42.7077C268.121 42.7077 268.485 38.8862 264.292 38.8862C260.099 38.8862 256.818 43.4356 256.818 43.4356Z"
          fill="#010205"
        />
        <path
          d="M280.879 44.347C280.879 44.347 279.421 42.1633 275.775 44.347C275.775 44.347 276.686 41.4354 279.603 41.7993C282.337 41.7993 280.697 44.5289 280.697 44.5289H280.879V44.347Z"
          fill="#010205"
        />
        <path
          d="M257.365 70.1862C249.891 68.7304 245.151 62.5432 242.052 52.7166C242.052 52.7166 242.417 70.9141 256.089 74.9175L257.365 70.3682V70.1862Z"
          fill="#010205"
        />
        <path
          d="M263.373 48.1666C263.92 48.1666 264.649 47.6206 264.467 46.8927C264.285 46.1648 263.92 45.6189 263.191 45.8009C262.462 45.9829 261.915 46.3468 262.097 47.0747C262.279 47.8026 262.644 48.3485 263.373 48.1666Z"
          fill="#010205"
        />
        <path
          d="M276.141 48.8957C276.688 48.8957 277.235 48.3498 277.235 47.6219C277.235 46.894 276.688 46.53 275.959 46.53C275.229 46.53 274.865 47.076 274.865 47.8039C274.865 48.5318 275.412 48.8957 276.141 48.8957Z"
          fill="#010205"
        />
        <path
          d="M273.222 154.986L279.785 169.908H233.481C230.747 170.272 233.481 148.981 243.325 149.527C252.44 151.165 273.404 155.168 273.404 155.168H273.222V154.986Z"
          fill="#7D4C34"
        />
        <path
          d="M231.838 169.727C230.744 160.628 236.578 150.255 238.401 148.618C240.224 146.98 242.047 148.8 243.505 149.346C231.474 158.08 235.849 169.727 235.849 169.727H231.838Z"
          fill="#010205"
        />
        <path
          d="M323.9 143.704C324.629 144.068 324.811 146.98 324.264 147.526L293.274 167.543L277.232 161.902L276.503 159.172L301.66 133.332C302.389 132.604 303.483 132.422 304.394 133.15L323.9 143.704Z"
          fill="#010205"
        />
        <path
          d="M323.535 146.253C324.264 146.617 324.264 147.527 323.535 148.072L293.274 168.818L276.684 161.539L301.841 136.608C302.571 135.88 303.664 135.698 304.576 136.426L323.535 146.253Z"
          fill="#FBFCFD"
        />
        <path
          d="M305.306 142.431C304.577 143.159 303.483 143.159 302.572 142.613C301.66 142.067 301.66 141.521 302.572 140.793C303.301 140.065 304.577 139.883 305.306 140.429C306.218 140.793 306.218 141.703 305.306 142.431Z"
          fill="#010205"
        />
        <path
          d="M300.387 145.524C299.475 144.978 299.293 144.25 300.205 143.704C300.569 143.34 302.028 142.976 302.939 143.522C303.668 144.068 303.668 144.796 302.939 145.342C302.939 145.342 301.481 146.069 300.569 145.524H300.387Z"
          fill="#010205"
        />
        <path
          d="M304.574 145.161C303.845 144.615 303.845 144.069 304.574 143.523C305.304 142.977 306.397 142.795 307.309 143.341C308.22 143.887 308.038 144.433 307.309 144.979C307.309 144.979 305.85 146.07 304.574 145.161Z"
          fill="#010205"
        />
        <path
          d="M261.009 166.451C280.15 174.276 283.614 175.186 285.072 174.458C286.53 173.73 285.801 173.184 289.082 172.639C292.364 172.093 293.458 168.635 293.458 168.635C293.458 168.635 302.755 164.996 303.484 164.632C304.031 164.268 303.849 162.266 303.849 162.266C303.849 162.266 307.495 161.72 308.224 160.446C308.953 158.99 306.948 158.626 302.208 157.717C297.468 156.807 284.707 156.807 284.707 156.807L283.796 154.441C283.796 154.441 290.176 150.983 291.452 148.982C292.728 146.98 292.182 144.25 290.905 143.704C289.629 143.341 288.171 145.524 285.983 146.616C283.796 147.708 276.322 148.982 275.775 149.528C275.228 150.074 268.118 156.443 268.118 156.443C268.118 156.443 262.285 163.358 261.009 166.451Z"
          fill="#7D4C34"
        />
        <path
          d="M290.177 165.178C290.177 165.178 296.193 164.086 301.115 163.177"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M284.889 153.896C286.894 152.804 290.176 150.802 291.087 149.346C292.363 147.345 291.816 144.615 290.54 144.069C289.264 143.705 287.806 145.889 285.618 146.981C283.431 148.072 275.957 149.346 275.41 149.892C274.863 150.438 272.493 152.622 270.123 154.624"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M284.527 156.99L283.615 154.624"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M303.484 162.811C303.484 162.811 307.13 162.265 307.859 160.991C308.588 159.535 307.859 158.443 303.119 157.897C298.379 157.352 291.452 157.534 287.624 157.352"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M285.981 173.912C286.345 173.73 287.074 173.366 288.533 173.002C291.814 172.456 292.908 168.999 292.908 168.999C292.908 168.999 302.205 165.359 302.934 164.995"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M259.366 55.6284C259.366 55.6284 257.543 61.4516 260.46 62.7255C262.829 63.6353 264.835 61.2697 265.017 59.6319C263.194 58.54 260.095 56.9022 259.366 55.6284Z"
          fill="#FBFCFD"
        />
        <path
          d="M267.022 60.9057C267.022 60.9057 266.111 60.3597 265.017 59.6318"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M259.366 55.6284C259.366 55.6284 257.543 61.4516 260.46 62.7255C262.829 63.6353 264.835 61.2697 265.017 59.6319C263.194 58.54 260.095 56.9022 259.366 55.6284Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M245.694 146.07C245.694 146.07 245.876 139.337 235.485 140.065"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M260.099 221.589C260.099 221.589 260.646 190.107 213.978 179.735C167.31 169.18 166.763 218.131 178.794 223.045C191.008 227.958 222.181 230.324 222.181 230.324C222.181 230.324 202.493 249.795 204.134 285.28L235.489 285.826C235.489 285.826 260.828 243.972 259.917 221.771H260.099V221.589Z"
          fill="#2A2F3E"
        />
        <path
          d="M250.069 176.46C250.069 176.46 248.793 183.557 244.418 187.378"
          stroke="#FBFCFD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M249.519 138.79C247.878 139.7 258.451 134.787 261.55 126.598"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M273.034 109.311C277.409 121.503 272.852 148.072 273.763 143.158"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M310.049 157.534L322.262 149.345"
          stroke="#010205"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
